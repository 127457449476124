.DropWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f4f6;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  color: #6b7280;
  padding: 20px;
  border: 1px dashed #00000020;
  background: #e9edf823;
}

.DropHover {
  position: relative;
  border: 1px dashed #a3a3a3;
}

.disabled,
.loading {
  pointer-events: none;
}

.disabled {
  opacity: 0.6;
}
